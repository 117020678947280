/* global DEBUG */
/* globals handlebarsTemplates */

(function ( $, _ ) {
    'use strict';

    // Establish the root object ('window' in the browser)
    var root = this;

    /**
     * @selector data-zg-role="compare-products" The plugin start if there is the selector in the dom when the page load
     */
    var SELECTOR = '[data-zg-role="compare-products"]';
    var REQUEST_URL = root.makeUrl( { module: 'eshop', manager: 'eshop', action: 'getProductsInfo' } );

    /**
     * AJAX CALL VALUES
     *
     * Enable the following options to request additional information for the product
     * Some of this options allow you to select a specific value to request.
     * Example:
     *     getCharacteristics -> true  would request all characteristics
     *     getCharacteristics -> x,y   would only get the information for the characteristics with ids x & y
     */

    /**
     * @param {string} [products] List of products id, if you want display specific products
     * @param {boolean} [forceResult] Forces to get the product info even if the shop configuration would prevent it
     * @param {boolean} [getCategories] If you want category associated in the object of each product
     * @param {string|boolean} [getCharacteristics] If you want caratteristics of each single product
     * @param {boolean} [getClassification]  If you want classification of each single product
     * @param {boolean} [getCustomValues]  If you want custom value of each single product
     * @param {boolean} [getDescriptions]  If you want descriptions of each single product
     * @param {string|boolean} [getImages]  If you want images of each single product
     * @param {string|boolean} [getOptions]  If you want options of each single product
     * @param {boolean} [getQuantity]  If you want warehouse quantity of each single product
     * @param {boolean} [getPrice]  If you want price of each single product
     * @param {boolean} [getPricePerCountry]  If you want price for country of each single product
     * @param {boolean} [getPromotions]  If you want promotions associated of each single product
     * @param {boolean} [getPhysicalCharacteristics]
     * @param {boolean} [getProperties]
     * @param {boolean} [getSeo] get the SEO url
     * @param {boolean} [getSkus]
     * @param {boolean} [getStandardOptions]
     * @param {boolean} [getTextile] If you want textile declaration for each single product
     * @param {boolean} [getUrl]
     */
    var DEFAULT_REQUEST = {
        products: null,
        // You could use this to force a language in the result
        // lang: false,
        forceResult: false,
        getCategories: true,
        getCharacteristics: false,
        getClassification: false,
        getCustomValues: false,
        getDescriptions: false,
        getImages: false,
        getLinkedProducts: false,
        getOptions: false,
        getQuantity: false,
        getPrice: false,
        getPricePerCountry: false,
        getPromotions: false,
        getPhysicalCharacteristics: false,
        getProperties: false,
        getSeo: false,
        getSkus: false,
        getStandardOptions: false,
        getTextile: false,
        getUrl: false
    };


    // SCRIPT OPTIONS

    /**
     * @param {string}  [product] The zg-product plugin will be initialised in order to retrieve the information from the products to be compared 
     * @param {string}  [addToCompare] Button that will try to add products to the compare product container
     * @param {string}  [cookieName] Name of the cookie that will store the product IDs that have been added to the container
     * @param {string}  [arrayPids] Array that will be used to add/remove products from the cookie
     * @param {string}  [maxComparedProducts] Maximum number of products to be added to the container
     * @param {string}  [numComparedProducts] Current number of products added to the container
     * @param {string}  [compareProductsMini] Small container displayed on the bottom of the screen 
     * @param {string}  [compareProductsModal] Modal container that will display the compared products
     * @param {string}  [badgeNumberOfProducts] Badge displayed in the mini container that will show the number of compared products
     * @param {string}  [removeAllComparedProducts] Button that will remove all the products from both containers
     * @param {string}  [btnToggleContainer] Button that will toggle the mini container
     * @param {boolean} [animateToggleContainer] Flag variable used for collapse the mini container when the page is loaded
     */
    var DEFAULTS = {
        product:                   '[data-zg-role="product"]',
        addToCompare:              '[data-zg-role="addToCompare"]',
        cookieName:                'ZGcompareProducts',
        arrayPids:                 [],
        maxComparedProducts:       3,
        numComparedProducts:       0,
        compareProductsMini:       '[data-zg-role="compare-products-container-mini"]',
        compareProductsModal:      '[data-zg-role="compare-products-container-modal"]',
        badgeNumberOfProducts:     '[data-zg-role="badge-number-of-products"]',
        removeAllComparedProducts: '[data-zg-role="remove-all-compared-products"]',
        btnToggleContainer:        '[data-zg-role="btn-toggle-container"]',
        animateToggleContainer:    false
    };


    // COMPARE PRODUCTS CLASS DEFINITION
    // ===========================

    /**
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var CompareProducts = function ( element, options ) {
        this.$element = $( element );

        this.options = {};
        this.request = {};
        this.updateOptions( options );

        this.$product                         = $( this.options.product );
        this.$addToCompare                    = $( this.options.addToCompare );
        this.$compareProductsMini             = $( this.options.compareProductsMini );
        this.$compareProductsModal            = $( this.options.compareProductsModal );
        this.$badgeNumberOfProducts           = $( this.options.badgeNumberOfProducts );
        this.$removeAllComparedProducts       = $( this.options.removeAllComparedProducts );
        this.$btnToggleContainer              = $( this.options.btnToggleContainer );

        // responses cache.
        this.cached = {};

        this.__setEventHandlers();
    };


    /**
     *
     * The whole plugin will work focused on the Compare Product Cookie. There we will store the product IDs to be compared.
     * When the plugin is initialised, we will check if the cookie exists. If does exist, we will get its content and
     */
    CompareProducts.prototype.init = function () {
        var valCookie = root.zgReadCookie( this.options.cookieName );
        var arrayValCookie;

        // CHECK IF THERE IS COOKIE
        if ( valCookie ) {
            arrayValCookie = valCookie.split( ',' );
            this.options.arrayPids = arrayValCookie;
            this.options.numComparedProducts = arrayValCookie.length;

            // It will not process the mini container if there are no products inserted
            if( this.options.numComparedProducts > 0 ) {
                this.updateProductsContainer();
            }
        }
    };


    /**
     *
     * @returns {Object}
     */
    CompareProducts.prototype.addToCompare = function ( pid ) {
        pid = pid.toString();
        var valCookie = root.zgReadCookie( this.options.cookieName );
        var arrayValCookie;

        // CHECK IF THERE IS COOKIE
        if ( !valCookie ) {
            this.options.arrayPids.push( pid );
            this.options.numComparedProducts++;
            this.$badgeNumberOfProducts.html( this.options.numComparedProducts );
            root.zgCreateCookie( this.options.cookieName, pid, 30);
        }
        else{

            arrayValCookie = valCookie.split( ',' );
            this.options.arrayPids = arrayValCookie;

            var i = _.indexOf( this.options.arrayPids, pid );

            // If the product has not been added yet
            if( i === -1 ){

                if( this.options.numComparedProducts < this.options.maxComparedProducts ){
                    this.options.arrayPids.push( pid );
                    this.options.numComparedProducts++;
                    this.$badgeNumberOfProducts.html( this.options.numComparedProducts );
                    valCookie = this.options.arrayPids.join( ',' );
                    root.zgEraseCookie( this.options.cookieName );
                    root.zgCreateCookie( this.options.cookieName, valCookie, 30);
                }
                else{
                    $( document ).trigger( 'zg-notification', [{
                        eventType: 'zg.compare-products',
                        message:   JS_TRANSLATIONS['compare_products.maxProductsAdded']
                    }] );
                }
            }
            else{
                $( document ).trigger( 'zg-notification', [{
                    eventType: 'zg.compare-products',
                    message:   JS_TRANSLATIONS['compare_products.productAlreadyAdded']
                }] );
            }
        }

        this.updateProductsContainer();
    };


    /**
     *
     * @returns {Object}
     */
    CompareProducts.prototype.removeProductFromCompare = function ( product ) {
        var $product = $( product);
        var pid = $product.data( 'zgCompareId').toString();

        $product.remove();

        var i = _.indexOf( this.options.arrayPids, pid );
        if( i != -1 ){
            this.options.arrayPids.splice( i, 1 );
            this.options.numComparedProducts--;
            this.$badgeNumberOfProducts.html( this.options.numComparedProducts );
            var valCookie = this.options.arrayPids.join( ',' );
            root.zgEraseCookie( this.options.cookieName );
            root.zgCreateCookie( this.options.cookieName, valCookie, 30);
        }

    };


    /**
     *
     * @returns {Object}
     */
    CompareProducts.prototype.removeAllComparedProducts = function ( ) {
        this.$compareProductsMini.empty();
        this.$compareProductsModal.empty();

        this.options.arrayPids = [];
        this.options.numComparedProducts = 0;
        this.$badgeNumberOfProducts.html( "0" );
        root.zgEraseCookie( this.options.cookieName );

        this.toggleContainer( 250 );
    };


    /**
     *
     * @returns {Object}
     */
    CompareProducts.prototype.insertProductIntoContainer = function ( arrayPids ) {
        var that = this;
        var cacheKey;

        var data = { products:  arrayPids,
                     getPrice:  true,
                     getImages: ["cart","medium"]};
        var callback = {
            success: (function ( data, response ) {
                that.$compareProductsMini.empty();
                that.$compareProductsModal.empty();

                _.each( response.products, function( product ){
                    that.$compareProductsMini.append( handlebarsTemplates.render( "compare-products-preview", product ) );
                    that.$compareProductsModal.append( handlebarsTemplates.render( "compare-products-modal", product ) );

                    $( document ).trigger( 'zg.compareProduct.productInsertedInContainer', [product.id] );
                });

                that.cached[cacheKey] = response;

            }).bind( this, data ),

        };


        cacheKey = JSON.stringify( this.request );

        if ( this.cached[cacheKey] ) {
            this.__onSuccess( this.cached[cacheKey] );
            this.__onComplete();
        } else {
            zgPost(REQUEST_PRODUCTS_URL, data, null, callback);
        }

    };



    /**
     *
     * Updates the number of products added to the mini container and it also calls the function that will get the product info
     */
    CompareProducts.prototype.updateProductsContainer = function ( ) {
        this.$badgeNumberOfProducts.html( this.options.numComparedProducts );
        this.getInfo( this.options.arrayPids );
    };


    /**
     * Request the product information from the server.
     * It uses the request data to generate a cache object, so the same request is not executed
     * twice.
     * The cache is created as a js object, reloading the page will clean the cache
     *
     */
    CompareProducts.prototype.getInfo = function ( pids ) {
        var cacheKey;
        this.request.products = pids;

        if ( this.request.products ) {

            cacheKey = JSON.stringify( this.request );

            if ( this.cached[cacheKey] ) {
                this.__onSuccess( this.cached[cacheKey] );
                this.__onComplete();
            } else {
                $.ajax( {
                    type:     'POST', // 'GET' breaks on the search version (too many parameters)
                    url:      REQUEST_URL,
                    dataType: 'json',
                    data:     this.request,

                    success: function ( response ) {
                        this.__onSuccess( response );
                        this.cached[cacheKey] = response;
                    }.bind( this ),

                    error: function ( response ) {
                        this.__onError( response );
                    }.bind( this ),

                    complete: function () {
                        this.__onComplete();
                    }.bind( this )
                } );
            }
        } else if ( DEBUG ) {
            console.warn( 'CompareProducts.getInfo - not requested: "products" missing' );
        }
    };


    /**
     * @method __onSuccess
     * Empties the mini container and also the modal. Then, it will append the product template to both, mini and modal containers
     * @fires document#zg.compareProducts.productInsertedInContainer.success On ajax call success
     */
    CompareProducts.prototype.__onSuccess = function ( response ) {
        var that = this;
        this.$compareProductsMini.empty();
        this.$compareProductsModal.empty();

        _.each( response.products, function( product ){
            that.$compareProductsMini.append( handlebarsTemplates.render( "compare-products-preview", product ) );
            that.$compareProductsModal.append( handlebarsTemplates.render( "compare-products-modal", product ) );

            $( document ).trigger( 'zg.compareProduct.productInsertedInContainer', [product.id] );
        });
    };


    /**
     * @method __onComplete
     * @fires document#zg.getProductInfo.complete Completed AJAX request.
     */
    CompareProducts.prototype.__onComplete = function () {
        if( this.options.animateToggleContainer && this.$element.hasClass( 'collapsed' ) ) {
            this.toggleContainer();
        }
        else{
            this.animateToggleContainer = true;
        }
        $( document ).trigger( 'zg.compareProducts.getProductsInfo.complete' );
    };



    /**
     * @method __onError
     * @fires document#zg-error Display error message if ajax request make an error
     */
    CompareProducts.prototype.__onError = function ( response ) {
        if ( DEBUG ) {
            console.log( 'CompareProducts.getProductsInfo - ERROR', response );
        }

        $( document ).trigger( 'zg-error', [{
            message: root.JS_TRANSLATIONS.genericErrorMsg
        }] );

        $( document ).trigger( 'zg.compareProducts.getProductInfo.error' );
    };


    /**
     *
     * Toggle the mini container. If the mini container is uncollapsed it will add a timer before collapsing it.
     */
    CompareProducts.prototype.toggleContainer = function ( delay ) {
        var that = this;

        if( this.$element.hasClass( 'collapsed' ) ){
            this.$element.removeClass( 'collapsed' );
            this.$btnToggleContainer.removeClass( 'collapsed' );
        }
        else{
            setTimeout( function(){
                that.$element.addClass( 'collapsed' );
                that.$btnToggleContainer.addClass( 'collapsed' );
            }, delay );
        }
    };




    /**
     *
     * @param options
     */
    CompareProducts.prototype.updateOptions = function ( options ) {
        _.extendOwn( this.options, options || {} );
        _.extendOwn( this.request, options || {} );
    };


    /**
     *
     * @private
     */
    CompareProducts.prototype.__setEventHandlers = function () {
        var that = this;

        this.$element.on( 'zg.compareProducts.updateOptions', (function ( e, options ) {
            this.updateOptions( options );
            this.getInfo();
        }).bind( this ) );

        this.$addToCompare.on( 'click.zg.compareProducts.addToCompare', (function () {
            var pid = this.$addToCompare.data( 'zgPid' );
            this.options.animateToggleContainer = true;
            this.addToCompare( pid );
        }).bind( this ) );

        this.$removeAllComparedProducts.on( 'click.zg.compareProducts.removeAllComparedProducts', (function () {
            this.removeAllComparedProducts();
        }).bind( this ) );


        this.$btnToggleContainer.on( 'click.zg.compareProducts.toggleContainer', (function () {
            this.toggleContainer();
        }).bind( this ) );


        $( document ).on( 'zg.compareProduct.productInsertedInContainer', function( e, pid ){
            var $comparedProduct = $( '[data-zg-compare-id="' + pid + '"]');
            $comparedProduct.find( '[data-zg-role="remove-from-compare"]' ).click( function( ){
                that.removeProductFromCompare( $comparedProduct )
            });
        });

        $( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
            $( element ).find( '[data-zg-role="addToCompare"]').on( "click", function(){
                that.options.animateToggleContainer = true;
                that.addToCompare( product.id );
            });
        });
    };




    /**
     * Process the request object to remove unnecessary information.
     * We go through all properties in the request and remove the falsy ones and any not present
     * in the original DEFAULT_REQUEST
     *
     * @param {Object} config
     * @private
     */
    CompareProducts.prototype.__processRequest = function ( config ) {
        var param;
        var value;
        var request = {};

        for ( param in DEFAULT_REQUEST ) {
            // Filter by values by the default properties and not falsy values.
            // We don't want to create an unnecessarily big request
            if (
                DEFAULT_REQUEST.hasOwnProperty( param ) &&
                config.hasOwnProperty( param ) &&
                config[param] // only truthy values
            ) {
                value = config[param];

                // if the value is a string split into array (necessary for backend).
                // This has to happen even if there is just one value.
                if ( _.isString( value ) ) {
                    value = value.split( ',' );
                }

                request[param] = value;
            }
        }

        this.request = request;
    };


    /**
     * Update the options and request properties with the an options object
     *
     * @param {Object} [options]
     *
     * @private
     */
    CompareProducts.prototype.updateOptions = function ( options ) {
        this.options = _.extendOwn( {}, DEFAULTS, this.options, options || {} );
        this.request = _.extendOwn( {}, DEFAULT_REQUEST, this.request, options || {} );
        this.__processRequest(this.request || {});
    };



    // COMPARE PRODUCTS DEFINITION
    // ============================

    function Plugin ( option, updateOptions ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.compareProducts' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.CompareProducts', (data = new CompareProducts( this, options )) );
            } else if ( updateOptions && typeof option === 'object' ) {
                data.updateOptions( option );
            }

            data.init();
        } );
    }

    $.fn.zgCompareProducts             = Plugin;
    $.fn.zgCompareProducts.Constructor = CompareProducts;


    // COMPARE PRODUCTS DATA-API
    // ===================

    // default product - called on page load
    $( function () {
        $( SELECTOR ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );

}.call( this, jQuery, _ ));

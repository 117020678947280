/**
 *
 * Manage autocomplete search
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * DISCLAIMER:
 *   I just re-wrote this.
 *   I'm not sure about what half of the input fields are
 *   supposed to do or why are we spliting the product name
 *   in " - "
 *
 */
(function ( $ ) { "use strict";

	/**
	 * @selector data-zg-role="product-finder-elastic" The plugin start if there is the selector in the dom when the page load
	 */

	var toggle                	= '[data-zg-role="product-finder-elastic"]';
	var toggle_form             = '[data-zg-role="product-finder-elastic-form"]';
	var toggle_search_input   	= '[data-zg-role="search-elastic-input"]';
	var toggle_search_type    	= '[data-zg-role="search-type"]';
	var toggle_search_id      	= '[data-zg-role="search-id"]';
	var toggle_product_id     	= '[data-zg-role="search-product_id"]';
	var toggle_product_name   	= '[data-zg-role="search-product_name"]';
	var toggle_typeahead_ul   	= '[data-zg-role="typeahead-ul-elastic"]';
	var toggle_opacity_search 	= '[data-zg-role="opacity-search"]';
	var toggle_btn_search 		= '[data-zg-role="btn-elastic-search"]';
	var toggle_btn_mobile_search = '#typeahead-search-button';
	var toggle_search_container = '#searchElasticContainer';
	var toggle_erase_btn      	= '#typeahead-erase-button';
	var toggle_close_btn		= '[data-zg-role="btn-close-elastic-search"]';

	//Code to simulate option selected: A is layoutA, B is layoutB, C is layoutC and D is layoutD
	//var layoutDesktopTemplate = 'D';

	//Code to simulate option selected for mobile: suggestions is A, categories is B, brands is C
	//var mobileAutocompleteSelected = 'categories';

	//code for mobile layout that show logo
	//var layoutMobileTemplate = true;

	var DEFAULTS = {
		layoutDesktopTemplate: 'A',
		mobileAutocompleteSelected: 'A',
		layoutMobileTemplate: 'A',
		suggestionsDesktopItems: 0,
		categoriesDesktopItems: 0,
		brandsDesktopItems: 0,

	};

	var ZG_Search = function ( element, config ) {
		var self = this;

		this.init = function ( element, config ) {
			var $element       = $(element);
			self.defaultOptions = _.clone( DEFAULTS );
			self.$element      = $element;
			self.config        = $.extend( self.defaultOptions, config, self.$element.data('config') || {} );
			self.$eventBus     = $(document);

			self.$toggle_form             = $element.find( toggle_form );
			self.$search_input 			  = $element.find( toggle_search_input );
			self.$typeahead_ul 			  = $element.find( toggle_typeahead_ul );
			self.$toggle_erase_btn 		  = $element.find( toggle_erase_btn );
			self.$toggle_btn_search       = $element.find( toggle_btn_search );
			self.toggle_btn_mobile_search = $element.find( toggle_btn_mobile_search );
			self.$toggle_search_container = $element.find( toggle_search_container );
			self.$toggle_opacity_search   = $element.find( toggle_opacity_search );
			self.$toggle_close_btn		  = $element.find( toggle_close_btn );

			self.$toggle_form.on("submit", self.searchProducts);
			if( self.config.layoutMobileTemplate === 'D' ){
				self.$element.addClass('mobile-show-logo');
			}
			self.__setEventHandlers();
			self.startTypeAhead();
		};

		this.selectElasticSearch = function () {
			var val = this.$menu.find('.active').attr('data-value')
			var val2 = val.replace(/<em>/gi, '');
			var valFinal = val2.replace(/<\/em>/gi, '');
			if (typeof val !== 'undefined') {
				this.$element
					.val(this.updater(valFinal))
					.change();

				if (this.ajax && this.ajax.loadProductPageBy != null){
					var id = this.$menu.find('.active').attr('data-id');
					var type = this.$menu.find('.active').attr('data-type');
					if( id > 0 ){
						this.$element.attr('data-id',id);
						this.$element.attr('data-type',type);

						if(type == 'product'){
							this.$element.attr('data-value',this.$menu.find('.active').attr('data-value'));
						}
					} else {
						this.$element.val(valFinal);
					}
					this.$element.closest( toggle_form ).submit();
				}
			}
			return this.hide();
		};

		this.setHeightContainer = function() {
			let res = $('[data-zg-role="elastic-right-column"]').outerHeight() + 'px';
			if ( $('[data-zg-role="elastic-right-column"]').outerHeight() > 0 ) {
				self.$typeahead_ul.css('min-height', res);
			} else {
				self.$typeahead_ul.css('min-height', '100px');
			}
		}

		this.showElasticSearch = function() {
			this.$menu.show();
			$('.truncate').succinct( { size: 90 } );//truncate the description
			$('.truncate-full-screen').succinct( { size: 160 } );//truncate the description full screen
			self.setHeightContainer();
			this.shown = true;
			return this;
		};

		this.hideElasticSearch = function () {
			this.$menu.hide();
			this.shown = false;
			self.$toggle_opacity_search.removeClass('opacity-search');
			if( self.config.layoutMobileTemplate === 'A' ) {
				self.$typeahead_ul.removeClass('height-100vh');
			}
			this.$menu.find('#elastic_mobile_search_button').remove();
			return this;
		};

		this.setRightContent = function (i, element, itemsValue, name, translation) {
			let container = i.find(`ul[data-elastic='${name}']`);

			if (element == null || element.length === 0) {
				container.removeClass( 'd-lg-block' ).addClass('d-none');
			} else if (element.length !== 0) {
				if( itemsValue === 0 ){
					container.removeClass( 'd-lg-block' ).addClass('d-lg-none');
				}

				let cssClass = 'heading-elastic';

				if ( self.config.layoutMobileTemplate === 'A' ) {
					cssClass = 'd-none d-lg-flex heading-elastic';
				}

				container.append('<p class="' + cssClass + '">' + JS_TRANSLATIONS[translation] + '</p>');

				for (let index = 0; index < element.length; index++){

					let liElement = `<li data-elastic="${name}-item"></li>`;

					if( index >= itemsValue){
						if (index < 2) {
							liElement = `<li class="d-block d-lg-none" data-elastic="${name}-item"></li>`;
						} else {
							liElement = `<li class="d-none" data-elastic="${name}-item"></li>`;
						}
					} else if ( index >= 2 ) {
						liElement = `<li class="d-none d-lg-block" data-elastic="${name}-item"></li>`;
					}

					container.append( liElement );
					let liresult = i.find(`li[data-elastic='${name}-item']`);
					liresult.eq(index).html(element[index]);
					liresult.eq(index).attr('data-value', element[index]);
					liresult.eq(index).attr('data-id', 0);
					liresult.eq(index).attr('data-type', name);
				}
			}
		};

		this.renderElasticSearch = function ( items ) {
			const that = this,
				suggestionsArr =[];
			let suggestions, categories, brands, resultName, resultDesc,
				resultImg, resultPrice, resultCurrency, resultId, resultType, resultUrl,
				allCharacteristicNotPresent = false, paddingRow = '', allItems = items;

			items = $(items).map(function (i, item) {
				resultName = item.product_data.name;
				resultDesc = item.product_data.short_description;
				resultImg = item.product_data.images.small;
				resultPrice = item.product_data.price.sell;
				resultCurrency = item.product_data.price.currency_symbol;
				resultId = item.product_id;
				resultType = item.type;
				resultUrl = makeUrl( { module: 'eshop', manager: 'product', pid: resultId } );
				suggestions = item.suggestions;
				categories = item.categories;
				brands = item.brands;
				let highlights = item.highlight;
				if ( that.ajax && that.ajax.displayField!=null && that.ajax.loadProductPageBy!=null ) {
					i = $(that.options.item).attr('data-value', item.product_data.name);
					i.find("span[data-elastic='name']").html(resultName);
					i.find("div[data-elastic='desc']").html(resultDesc);
					if (highlights != null && Array.isArray(highlights)) {
						highlights.forEach((param)=>{
							if (param.field == '>names<'){
								i.find("span[data-elastic='name']").html(param.values);
							}
							if (param.field == '>short_description<') {
								i.find("div[data-elastic='desc']").html(param.values);
							}
						});
					}
					i.attr('data-id', resultId);
					i.attr('data-type', resultType);
					i.find("a").attr('href', resultUrl);
					i.find("img").attr('src', resultImg);
					i.find("img").attr('alt', resultName);
					i.find("span[data-elastic='price']").html(resultPrice);
					i.find("span[data-elastic='currency']").html(resultCurrency);

						//if (suggestions == null || suggestions.length === 0 || self.config.suggestionsDesktopItems === 0) {
						if (suggestions == null || suggestions.length === 0 ) {
							suggestionsArr.length = 0;
						} else {
							for (let sug in suggestions) {
								suggestionsArr.push(suggestions[sug]);
								if (suggestionsArr.length > 10) {
									break;
								}
							}
						}

						self.setRightContent( i, suggestionsArr, self.config.suggestionsDesktopItems,'suggestions', 'Suggestions' );

						self.setRightContent( i, categories, self.config.categoriesDesktopItems,'categories', 'Categories' );

						self.setRightContent( i, brands, self.config.brandsDesktopItems,'brands', 'Brands' );

				} else {
					i = $(that.options.item).attr('data-value', item);
					i.find( 'a' ).html( that.highlighter( item )) ;
				}
				return i[0];
			});

			items.first().addClass( 'active' );

			if( self.config.layoutDesktopTemplate === 'C' ) {
				 paddingRow = "p-2 ";
			} else if ( self.config.layoutDesktopTemplate === 'B' ) {
				paddingRow = "px-2 pt-2 pt-lg-3 pb-2 pb-lg-3 ";
			}

			this.$menu.html( `<div class="row no-gutters row-custom-width ${paddingRow}relative" data-zg-role="elastic-row"></div>` );

			this.$menu.find( '[data-zg-role="elastic-row"]' ).html( items );

			if( self.config.layoutDesktopTemplate === 'D' ) {
				this.$menu.find( '[data-zg-role="elastic-row"]' ).prepend(`<p class="d-none d-lg-block w-100 text-left px-3 related-product-heading text-capitalize">${JS_TRANSLATIONS['related_products']}</p>`);
			}

			//Hide right column when data is not present
			if( (allItems[0].suggestions == null || allItems[0].suggestions.length === 0 || self.config.suggestionsDesktopItems === 0) &&
				(allItems[0].categories == null || allItems[0].categories.length === 0 || self.config.categoriesDesktopItems === 0) &&
				(allItems[0].brands == null || allItems[0].brands.length === 0 || self.config.brandsDesktopItems === 0) ) {
				//self.$typeahead_ul.find('[data-zg-role="elastic-right-column"]').attr('style', 'display:none;');
				let rightColumn = self.$typeahead_ul.find('[data-zg-role="elastic-right-column"]');
				if( self.config.mobileAutocompleteSelected !== 0 ){
					rightColumn.addClass( 'd-block d-lg-none' );
				} else {
					rightColumn.addClass( 'd-none' );
				}
				self.$typeahead_ul.find( '[data-zg-role="elastic-row"]' ).attr('style', 'width:100%;');
				self.$typeahead_ul.find( '.result-product-container' ).addClass('products-only');
				allCharacteristicNotPresent = true;
				if ( self.config.layoutDesktopTemplate === 'D' ) {
					self.$typeahead_ul.find( '[data-zg-role="elastic-row"]' ).addClass('no-right-column');
				}
			}

			function  addProductHeading() {
				if ( self.config.layoutMobileTemplate === 'B' ) {
					$( '.result-product-container' ).first().append(`<p class="d-block d-lg-none order-1 mt-1 heading-elastic">${JS_TRANSLATIONS['Products']}</p>`);
				}
			}

			//if ( allCharacteristicNotPresent === false){
				switch ( self.config.mobileAutocompleteSelected ) {
					case 'A':
						//if( allItems[0].suggestions == null || allItems[0].suggestions.length === 0 || self.config.suggestionsDesktopItems === 0) {
						if( allItems[0].suggestions == null || allItems[0].suggestions.length === 0) {
							self.$typeahead_ul.find( '.result-product-container' ).addClass('products-only');
						} else {
							addProductHeading();
						}
						break;
					case 'B':
						//if( allItems[0].categories == null || allItems[0].categories.length === 0 || self.config.categoriesDesktopItems === 0) {
						if( allItems[0].categories == null || allItems[0].categories.length === 0) {
							self.$typeahead_ul.find( '.result-product-container' ).addClass('products-only');
						} else {
							addProductHeading();
						}
						break;
					case 'C':
						//if( allItems[0].brands == null || allItems[0].brands.length === 0 || self.config.brandsDesktopItems === 0) {
						if( allItems[0].brands == null || allItems[0].brands.length === 0) {
							self.$typeahead_ul.find( '.result-product-container' ).addClass('products-only');
						} else {
							addProductHeading();
						}
						break;
				}
			//}

			//Clone send button because it was impossible to set it with css in the desired position in mobile
			self.toggle_btn_mobile_search.clone().appendTo( self.$typeahead_ul ).attr('id', 'elastic_mobile_search_button').addClass('d-block d-lg-none').removeClass('d-none d-lg-block');

			this.$menu.find( '#elastic_mobile_search_button' ).text( JS_TRANSLATIONS['elastic.search_button'] + " '" + self.$search_input.val()+"'" );

			this.$menu.removeClass( 'loading show' );

			return this;
		};

		this.keyboardNavigation = function ( key, $menu ){
			var selectedElem = false;
			var $elasticRightItems = $menu.find( '[data-zg-role="elastic-right-column"] li' ),
				$elasticItems = $menu.find( '.elastic-item-container' ), currentIndex,
				$selectedBox = $menu.find( '.selected' );

			for (var i = 0, n = $elasticRightItems.length; i < n; i++ ) {
				if( $elasticRightItems[i].classList.contains('selected') ) {
					$selectedBox = $( $elasticRightItems[i] );
					selectedElem = true;
					currentIndex = i;
					break;
				}
			}

			if ($selectedBox.length !== 0) {
				selectedElem = true
			}
			$selectedBox.removeClass('selected');

			switch(key){
				case 40: // down arrow
					if( !selectedElem || ($selectedBox.hasClass('elastic-item-container') === false && ( $elasticRightItems.length === (currentIndex + 1) ) ) ){
						if( !$selectedBox || $selectedBox.length === 0 || ( $elasticRightItems.length === (currentIndex + 1) )  ) {
							$elasticRightItems.first().addClass('selected')
						}else{
							$elasticItems.first().addClass('selected')
						}
					}else{
						if($selectedBox.hasClass('elastic-item-container')) {
							if($selectedBox.parents('.result-product-container').is(':last-child')){
								$elasticItems.first().addClass('selected')
							}else {
								$selectedBox.parents('.result-product-container').next().find('.elastic-item-container').addClass('selected')
							}
						}else{
							$elasticRightItems[currentIndex + 1].classList.add('selected');
						}
					}
					break
				case 38: // up arrow
					if( !selectedElem || ($selectedBox.hasClass('elastic-item-container') === false && ( currentIndex === 0 ) ) ){
						if( !$selectedBox || ( currentIndex === 0 ) || $selectedBox.length === 0  ) {
							$elasticRightItems.last().addClass('selected')
						}else{
							$elasticItems.last().addClass('selected')
						}

					}else{
						if($selectedBox.hasClass('elastic-item-container')) {
							if($selectedBox.parents('.result-product-container').is(':first-child')){
								$elasticItems.last().addClass('selected')
							}else {
								$selectedBox.parents('.result-product-container').prev().find('.elastic-item-container').addClass('selected')
							}
						}else{
							$elasticRightItems[currentIndex - 1].classList.add('selected');
						}
					}
					break
				case 37: //left arrow
					if($selectedBox.hasClass('elastic-item-container')){
						$elasticRightItems.first().addClass('selected')
					}else{
						$elasticItems.first().addClass('selected')
					}
					break
				case 39: //right arrow
					if($selectedBox.hasClass('elastic-item-container')){
						$elasticRightItems.first().addClass('selected')
					}else{
						$elasticItems.first().addClass('selected')
					}
					break
			}
		};

		this.keyupElasticSearch = function ( e ){

			switch(e.keyCode) {

				case 16: // shift
				case 17: // ctrl
				case 18: // alt
					break
				case 40: // down arrow
				case 39: //left arrow
				case 38: // up arrow
				case 37: //left arrow
					self.keyboardNavigation(e.keyCode,this.$menu)
					break

				case 9: // tab
				case 13: // enter
					if (!this.shown) return
					var $selectedBox = this.$menu.find('.selected')
					if ($selectedBox.length !== 0 && $selectedBox.hasClass('elastic-item-container')) {
						$selectedBox.trigger('click')
					}else if($selectedBox.length !== 0){
						$("#product-finder-elastic").find('[data-zg-role="search-elastic-input"]').val($selectedBox.text());

						$("#product-finder-elastic").submit();
					}else{
						$("#product-finder-elastic").submit();
					}
					break

				case 27: // escape
					if (!this.shown) return
					this.hide()
					break

				default:
					this.ajax ? this.ajaxLookup() : this.lookup()
			}

			e.stopPropagation()
			e.preventDefault()
		};

		this.blurElasticSearch = function () {
			this.focused = false
		};

		this.clickElasticSearch = function ( e ) {
			if (e.target.tagName != "LI" && e.target.tagName != 'EM'){
				return;
			}
			this.select();
			this.$element.focus();
		};

		/*
		* set right content to show mobile option
		* */
		this.setRightColumnStructure = function( selected ) {
			let rightContent;

			switch( selected ){
				case 'A':
					rightContent = `<ul data-elastic="suggestions"></ul><ul data-elastic="categories" class="d-none d-lg-block"></ul><ul data-elastic="brands" class="d-none d-lg-block"></ul>`;
					break;
				case 'B':
					rightContent = `<ul data-elastic="suggestions" class="d-none d-lg-block"></ul><ul data-elastic="categories"></ul><ul data-elastic="brands" class="d-none d-lg-block"></ul>`;
					break;
				case 'C':
					rightContent = `<ul data-elastic="suggestions" class="d-none d-lg-block"></ul><ul data-elastic="categories" class="d-none d-lg-block"></ul><ul data-elastic="brands"></ul>`;
					break;
				case '0':
					rightContent = `<ul data-elastic="suggestions" class="d-none d-lg-block"></ul><ul data-elastic="categories" class="d-none d-lg-block"></ul><ul data-elastic="brands" class="d-none d-lg-block"></ul>`;
					break;
				default:
					rightContent = `<ul data-elastic="suggestions"></ul><ul data-elastic="categories"></ul><ul data-elastic="brands"></ul>`;
			}

			return rightContent;

		};

		this.startTypeAhead = function() {
			const url = window.makeUrl({module: 'eshop', action: 'autoComplete'});

			const rightContent = this.setRightColumnStructure( self.config.mobileAutocompleteSelected );

			const hideDescriptionMobileLayoutB = (self.config.layoutMobileTemplate === 'B') ? 'd-none ' : '';

			let structureA = `<div class="result-product-container line-bottom padding-a col-12">
								<a href="" data-elastic="link" class="elastic-item-link">
									<div class="elastic-item-container d-flex">
										<div class="elastic-img-container col-1 col-lg-2">
											<img class="img-fluid elastic-img" src="" alt="">
										</div>
										<div class=" col-11 col-lg-10 pr-0 text-left position-static" data-zg-role="elastic-data-container">
											<p class="mb-2 mw-85"><span data-elastic="name"></span></p>
											<div class="elastic-item-desc truncate ${hideDescriptionMobileLayoutB} d-lg-block" data-elastic="desc"></div>
											<div class="elastic-item-price">
												<span data-elastic="currency"></span><span data-elastic="price"></span>
											</div>
										</div>
									</div>
								</a>
								<div id="right-column" data-zg-role="elastic-right-column">${rightContent}</div>
							</div>`;

			let structureB = `<div class="result-product-container line-bottom-mobile col-12 col-lg-6 ">
									<a href="" data-elastic="link" class="elastic-item-link">
										<div class="elastic-item-container line-bottom-mobile d-flex">
											<div class="elastic-img-container col-1 col-lg-4">
												<img class="img-fluid" src="" alt="">
											</div>
											<div class="col-11 col-lg-8 pr-0 text-left" data-zg-role="elastic-data-container">
												<p class="mb-0 mb-lg-1"><span data-elastic="name"></span></p>
												<div class="elastic-item-desc truncate ${hideDescriptionMobileLayoutB} d-lg-block mb-2" data-elastic="desc"></div>
												<div class="price"><span data-elastic="currency"></span><span data-elastic="price"></span></div>
											</div>
										</div>
									</a>
									<div id="right-column" data-zg-role="elastic-right-column">${rightContent}</div>
								</div>`;

			let structureC = `<div class="result-product-container line-bottom-mobile col-12 col-lg-4">
									<a href="" data-elastic="link" class="elastic-item-link">
										<div class="elastic-item-container line-bottom-mobile d-flex flex-wrap justify-content-center">
											<div class="elastic-img-container col-1 col-lg-10">
												<img class="img-fluid mb-0 mb-lg-3" src="" alt="">
											</div>
											<div class="w-100 d-none d-lg-block"></div>
											<div class="col-11 text-left pr-0 pr-lg-2" data-zg-role="elastic-data-container">
												<p class="mb-0 mb-lg-2"><span data-elastic="name"></span></p>
												<div class="price"><span data-elastic="currency"></span><span data-elastic="price"></span></div>
												<div class="${hideDescriptionMobileLayoutB} d-lg-none elastic-item-desc truncate margin-bottom-sm" data-elastic="desc"></div>
											</div>
										</div>
									</a>
									<div id="right-column" data-zg-role="elastic-right-column">${rightContent}</div>
								</div>`;

			let structureD = `<div class="result-product-container line-bottom-mobile col-12 col-lg-4 mb-lg-5">
									<a href="" data-elastic="link" class="elastic-item-link">
										<div class="elastic-item-container line-bottom-mobile desktop">
											<div class="col-10 col-lg-12 mb-lg-5" data-zg-role="elastic-data-container">
												<p class="mb-0 mb-lg-2 mb-lg-3"><span data-elastic="name"></span></p>
												<div class="price"><span data-elastic="currency"></span><span data-elastic="price"></span></div>
											</div>
											<div class="elastic-img-container col-2 col-lg-12 mb-lg-4">
												<img class="img-fluid image-full-screen" src="" alt="">
											</div>
											<div class="col-12">
												<div class="elastic-item-desc truncate-full-screen d-none d-lg-block mb-2" data-elastic="desc"></div>
											</div>
										</div>
										<div class="elastic-item-container line-bottom-mobile mobile">
											<div class="elastic-img-container col-1 col-lg-4">
												<img class="img-fluid" src="" alt="">
											</div>
											<div class="col-11 col-lg-8 pr-0 text-left" data-zg-role="elastic-data-container">
												<p class="mb-0 mb-lg-1"><span data-elastic="name"></span></p>
												<div class="elastic-item-desc truncate ${hideDescriptionMobileLayoutB} d-lg-block mb-2" data-elastic="desc"></div>
												<div class="price"><span data-elastic="currency"></span><span data-elastic="price"></span></div>
											</div>
										</div>
									</a>
									<div id="right-column" data-zg-role="elastic-right-column">${rightContent}</div>
								</div>`;

			let structure, itemsShow = 5;

			switch ( self.config.layoutDesktopTemplate ) {
				case 'A':
					structure = structureA;
					break;
				case 'B':
					structure = structureB;
					break;
				case 'C':
					structure = structureC;
					break;
				case 'D':
					structure = structureD;
					itemsShow = 3;
					break;
			}

			self.$search_input.typeahead({
				ajax: {
					url: url,
					preDispatch: function (query) {
						self.$toggle_opacity_search.addClass('opacity-search');
						self.$typeahead_ul[0].style.display = "block";
						self.$typeahead_ul.addClass( 'loading show' );
						return {
							action: 'autoComplete',
							key: query,
							limit: 5,
							orderBy : 'name',
							sortOrder : 'ASC',
							maxCharsForProduct: 70
						};
					},
					dType : 'type',
					displayField : 'value',
					loadProductPageBy : 'id'
				},
				sorter : 'none',
				items  : itemsShow,
				menu: self.$typeahead_ul,
				select: self.selectElasticSearch,
				show: self.showElasticSearch,
				hide: self.hideElasticSearch,
				render: self.renderElasticSearch,
				arrowNavigation: self.arrowNavigation,
				keyup: self.keyupElasticSearch,
				blur: self.blurElasticSearch,
				click: self.clickElasticSearch,
				item: structure
			});
		}

		this.searchProducts = function() {
			var productName = self.$search_input.val();
			if ( !productName || productName==="" ) return false;

			var type = self.$search_input.data('type') || "";
			var id   = self.$search_input.data('id')   || "";

			// we are getting here only once. we don't need to add this to the "self" object
			self.$toggle_form.find( toggle_search_type ).val( type );
			self.$toggle_form.find( toggle_search_id ).val( id );
			self.$toggle_form.find( toggle_product_id ).val( id );
			self.$toggle_form.find( toggle_product_name ).val( productName.split(" - ")[0] );

			self.$toggle_form.find(".characteristic-key").val(productName);
		}

		this.__setEventHandlers = function () {
			self.$search_input.click(function(){
				self.$typeahead_ul.find('.selected').removeClass('selected');
			});
			// Erase query and close input
			self.$toggle_erase_btn.click( function(){
				if(self.$search_input[0].value != "") {
					self.$search_input[0].value = "";
				} else {
					self.$toggle_search_container.addClass('d-none');
					self.$toggle_btn_search.addClass('d-block').removeClass('d-none');;
					self.$toggle_opacity_search.removeClass('opacity-search');
				}
			});

			self.$toggle_close_btn.click( function(){
				self.$toggle_search_container.addClass('d-none');
				self.$toggle_btn_search.addClass('d-block').removeClass('d-none');
				self.$toggle_opacity_search.removeClass('opacity-search');
			});

			// Toggle the search input
			self.$toggle_btn_search.click( function(){
				self.$toggle_opacity_search.addClass('opacity-search');
				self.$toggle_btn_search.addClass('d-none').removeClass('d-block');
				self.$typeahead_ul.hide();
				if( self.$toggle_search_container.hasClass( 'd-none' ) ){
					self.$toggle_search_container.removeClass( 'd-none' );
					self.$search_input.focus();
				}
				else {
					self.$toggle_search_container.addClass( 'd-none' );
				}

			});

			self.$toggle_opacity_search.click( function(){
				self.$toggle_search_container.addClass('d-none');
				self.$toggle_btn_search.addClass('d-block').removeClass('d-none');
				self.$toggle_opacity_search.removeClass('opacity-search');
				self.$typeahead_ul.hide();
			});

			var resizeHeight = _.debounce( function() { self.setHeightContainer() }, 100);
			$( window ).on( "resize", resizeHeight );
		}

		this.init( element, config );
	};

	$.fn.ZG_search = function ( config ) {
		return this.each( function () {
			var $this = $(this);
			var data = $this.data('zgSearch');
			if (!data) {
				$this.data('zgSearch', (data = new ZG_Search( this, config )) );
			}
		});
	};

	// Initilize Script
	$(toggle).ZG_search( window.ZG_CONFIG );
})( jQuery );
var _paq = window._paq = window._paq || [];
/*PAYPAL EXPRESS checking payment*/
(function ($) {
    var statePayment = window.localStorage.getItem('state_payment');
    var idPayment = window.localStorage.getItem('is_payment');
    var GTMPayment = window.localStorage.getItem('pushMTMPayment');

    var i = 0,
        intervalTime = 4000,  // 3 seconds
        limit = 40;						// 5 times - 15 seconds
    window.paymentMTMCheck= function(){
        statePayment = window.localStorage.getItem('state_payment');
        idPayment = window.localStorage.getItem('is_payment');
        GTMPayment = window.localStorage.getItem('pushMTMPayment');
        if(idPayment != null){
            var looper = setInterval(

                function() {
                    //console.log(idPayment);
                    var request = {
                            orderId: idPayment
                        },
                        url = window.makeUrl({
                            module: 'eshop',
                            action: 'getStatusOrder'
                        });

                    $.getJSON(url, request, function (response) {
                        //PUSH EVENT PURCHASE IF payment is done == 2
                        idPayment = window.localStorage.getItem('is_payment');
                        if(response.status_id==2 && idPayment != null){
                            //console.log('push datalayer');
                            eval(GTMPayment);
                            pushMTMPayment();
                            clearInterval(looper);
                            localStorage.removeItem('state_payment');
                            localStorage.removeItem('is_payment');
                            localStorage.removeItem('pushMTMPayment');
                            if(i != 0){
                                setTimeout(function(){
                                    location.reload();
                                },1000);
                            }
                        }

                    });

                    if (i >= limit) {
                        // * Stop checking *
                        clearInterval(looper);
                        //clear data in localstore
                        localStorage.removeItem('state_payment');
                        localStorage.removeItem('is_payment');
                        localStorage.removeItem('pushMTMPayment');

                    }

                    i++;
                },

                intervalTime);
        }
    }
    $(document).ready(function() {


        if(statePayment=='trying'){
            paymentMTMCheck();
        }
    });
})(jQuery);

/**
 * Add / Remove from Cart
 *
 * Required event: zg.product.addedToCart
 * Required data: status, productObj, dataSent
 */
$( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {

    _paq.push(['addEcommerceItem',
        productObj.id, // (Required) productSKU
        productObj.name || '', // (Optional) productName
        window.JS_TRANSLATIONS['category.name'] || '', // (Optional) productCategory
        productObj.price.sell || '', // (Recommended) price
        dataSent.quantity || 1 // (Optional, defaults to 1) quantity
    ]);

} );

/**
 * Required event: zg.product.removedFromCart
 * Required data: type, status, data
 */
$( document ).on( 'zg.product.removedFromCart', function ( e, type, status, data ) {

    if( type === 'cart' ){
        _paq.push(['removeEcommerceItem',
            data.product_id, // (Required) productSKU
        ]);
    }

} );



$( document ).on( 'zg.getProductListInfo.success', function ( e, type, response, showCart ) {
    if(type == 'cart'){
        let text = location.href;
        let result = text.includes('order/action/view');
        if(!result) {
            let prods = response.products_in_cart;
            for (var i = 0; i < prods.length; i++) {
                window._paq.push(['addEcommerceItem',
                    prods[i].sku, // (Required) productSKU
                    prods[i].name, // (Optional) productName
                    parseFloat(prods[i].price), // (Recommended) price
                    prods[i].quantity // (Optional, defaults to 1) quantity
                ]);
            }
            /*GA4 integration*/
            /*
            let prods = response.products_in_cart;
            let products= [];
            for (var i = 0; i < prods.length; i++) {
                products.push({
                    sku : prods[i].sku, // (Required) productSKU
                    name : prods[i].name, // (Optional) productName
                    price : parseFloat(prods[i].price), // (Recommended) price
                    quantity : prods[i].quantity // (Optional, defaults to 1) quantity
                });

            }
            window._mtm.push(
                {
                    "event":"cart_update",
                    "ecommerce":{
                        "total":parseFloat(response.total_products.total_no_shipcost),
                        "products":products
                    }
                }
            );
            */
            

            // Pass the Cart's Total Value as a numeric parameter
            window._paq.push(['trackEcommerceCartUpdate', parseFloat(response.total_products.total_no_shipcost)]);
        }
    }

} );
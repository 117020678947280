// add your site specific javascript here
$( document ).ready(function() {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	//Return to top
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click( function() {
		console.log("clicked button!");
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});

	var $searchContainer = $( '#searchContainer' );
	$( '[data-zg-role="btn-search"]' ).click( function(){
		if( $searchContainer.hasClass( 'd-none' ) ){
			$searchContainer.removeClass( 'd-none' );
		}
		else {
			$searchContainer.addClass( 'd-none' );
		}
	});


	//Increasing-decreasing qty input
	$( '[data-zg-role="change-qty"]' ).click( function(){
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
			step = 0.01;
		}
		else {
			step = 1;
		}
		qty = Number(qty);

		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
				$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});

	/*Added to calculate the height to center the arrows regard to the image height*/
	function heightArrowsSlider( slider ) {
		var topHeight = ( $( '.'+slider ).find( 'img' ).height()/100*60);
		$( '.slick-arrow' ).css( 'top' , topHeight+'px' );
	}

	$( '.slider-related-post' ).on( 'init', function( slick ) {
		heightArrowsSlider( 'slider-related-post' );
	});

	$( window ).on( 'resize' , _.debounce( function() {
		heightArrowsSlider( 'slider-related-post' );
	}, 200));

	$( '.slider-related-post' ).slick({
		dots: false,
		infinite: true,
		speed: 400,
		slidesToShow: 3,
		slidesToScroll: 3,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

});
( function ($) {
    'use strict';

    var root      = this,
        selector    = '[data-zg-role="zg-bookingSystem"]';

    // script options
    var DEFAULTS = {
        selectorContainer: '[data-zg-role="zg-bookingSystemContainer"]',
        calendarContainer: '[data-zg-role="calendar"]',
        bookingSystemTab: '[data-zg-role="BookingSystem-tab"]',

        modalBookingSystem: '[data-zg-role="ModalBookingSystem"]',
        dateinit: '[data-zg-role="dateinit"]',
        sendBooking: '[data-zg-role="sendBooking"]',
        formBookingSystem: '[data-zg-role="formBookingSystem"]',
        bookingSystemNote: '[data-zg-role="bookingSystemNote"]',
    }

    /**
     * @description Booking System Constructor
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var BookingSystem = function ( element, options ) {

        this.$element = $( element );
        this.options  = options;

        this.$selectorContainer = this.$element.find( this.options.selectorContainer );
        this.$calendarContainer = $( this.options.calendarContainer );
        this.$bookingSystemTab = $( this.options.bookingSystemTab );

        this.$modalBookingSystem = $( this.options.modalBookingSystem );
        this.$dateinit = this.$modalBookingSystem.find( this.options.dateinit );
        this.$saveBooking = this.$modalBookingSystem.find( this.options.sendBooking );
        this.$bookingSystemNote = this.$modalBookingSystem.find( this.options.bookingSystemNote );

        this.__setEventHandlers();
    }

    BookingSystem.prototype.getBookingByRange = function ($range) {
        var that = this,
            urlBookingSystemGetByRange = window.makeUrl({ module:'b2b',manager:'b2b',action:'getBookingByRange'});

        $.ajax({
            url: urlBookingSystemGetByRange,
            dataType: 'json',
            type: 'GEt',
            data: $range,
            beforeSend: function () {
                that.$calendarContainer.addClass('loading');
            },
            success: function (xhr_result) {
                that.$calendarContainer.fullCalendar('renderEvents', xhr_result, true);
            },
            complete: function () {
                that.$calendarContainer.removeClass('loading');
            },

            fail: function () {
                $( document ).trigger( 'zg-error', [{
                    eventType: '',
                    message: window.JS_TRANSLATIONS['BookingSystem.msg.error']
                }]);
            }
        });
    }

    BookingSystem.prototype.saveBooking = function ($aData) {
        var that = this,
            urlBookingSystem = window.makeUrl({ module:'b2b',manager:'b2b',action:'saveBooking'});

        $.ajax({
            url: urlBookingSystem,
            dataType: 'json',
            type: 'POST',
            data: {data: $aData},
            beforeSend: function () {
                that.$modalBookingSystem.find('.modal-body').addClass('loading');
            },
            success: function (xhr_result) {

                if (xhr_result.error) {
                    $( document ).trigger('zg-error', [{
                        eventType: 'saveBoking',
                        message: 'Error: missing booking data'
                    }]);

                    return;
                }

                var event = {
                    title: 'occupied',
                    start: xhr_result.starts_at,
                    end: xhr_result.ends_at,
                };

                $( document ).trigger( 'zg-notification', [{
                    eventType: '',
                    message:   window.JS_TRANSLATIONS['BookingSystem.msg.save']
                }]);

                that.$calendarContainer.fullCalendar('renderEvent', event, true);
            },
            complete: function () {
                that.$modalBookingSystem.modal('hide');
                that.$modalBookingSystem.find('.modal-body').removeClass('loading');
            },

            fail: function () {
                $( document ).trigger( 'zg-error', [{
                    eventType: '',
                    message: window.JS_TRANSLATIONS['BookingSystem.msg.saveerror']
                }]);
            }
        });
    }

    BookingSystem.prototype.__setEventHandlers = function () {
        var that = this;

        this.$bookingSystemTab.on('click.zg.bookingSystem', ( function (e) {
            e.preventDefault();

            setTimeout(function () {
                that.$selectorContainer.removeClass('loading');
                that.$calendarContainer.fullCalendar({
                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'agendaWeek'
                    },

                    selectable: true,
                    editable: false,
                    height: 450,
                    minTime: minTime,
                    maxTime: maxTime,
                    defaultView: 'agendaWeek',
                    slotDuration: slotDuration,
                    allDaySlot: false,
                    slotLabelFormat:'HH:mm',
                    select: function (start, end, allDay) {
                        var endtime = $.fullCalendar.formatDate(end, 'YYYY-MM-DD HH:mm:ss');
                        var starttime = $.fullCalendar.formatDate(start, 'YYYY-MM-DD HH:mm:ss');

                        that.$dateinit.html(starttime + ' - ' + endtime);

                        that.$bookingSystemNote.val('');
                        that.$saveBooking.data({'starts_at': starttime, 'ends_at': endtime});

                        that.$modalBookingSystem.modal('show');
                    },
                    viewRender: function (view, element) {
                        var range = {
                            'start': view.start.format('YYYY-MM-DD'),
                            'end': view.end.format('YYYY-MM-DD'),
                        };

                        that.$calendarContainer.fullCalendar('removeEvents');

                        that.getBookingByRange(range);
                    },
                    validRange: {
                        start: moment().format('YYYY-MM-DD'),
                        end: '2200-01-01'
                    },
                });
            },300);

        }).bind( this ));

        this.$saveBooking.on('click.zg.bookingSystem', ( function (e) {
            var $this = $( e.target ),
                $aData  = {};

                $aData = {
                    'starts_at': $this.data('starts_at'),
                    'ends_at': $this.data('ends_at'),
                    'notes': that.$bookingSystemNote.val()
                };

                this.saveBooking($aData);

        }).bind( this ));
    }

    //Booking System Definition Jquery
    function Plugin () {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.bookingSystem' );
            var options = $.extend( {}, DEFAULTS, root.ZG_CONFIG || {}, $this.data() );

            if ( !data ) {
                $this.data( 'zg.bookingSystem', new BookingSystem( this, options ) );
            }
        } );
    }

    $.fn.zgBookingSystem             = Plugin;
    $.fn.zgBookingSystem.Constructor = BookingSystem;

    $(function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        });
    });

}).call( this, jQuery );
/**
 * Manage payment methods in confirm page. Send to backend the payment method selected and change totals
 *
 * @author: Pietro Vignola <pvignola[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event document#zg.selectPayment.paymentReady Form for select payment method is ready
 * @type {object}
 * @property {int} - id of payment method selected
 * @property {string} - payment info if there is data-payment-info in html of the selector
 */

(function ( $, _ ) { /* global JS_TRANSLATIONS, makeUrl, ZG_CONFIG */
	'use strict';

	/**
	 * @selector data-zg-role="select-payment-method" The plugin start if there is the selector in the dom when the page load
	 */

	var selector = '[data-zg-role="select-payment-method"]';

	/**
	 * @param {string} [selector] Radio button with payment method value
	 */

	var DEFAULTS = {
		selector: 'input[name="paymentRadios"]'
	};

	// SELECT PAYMENT CLASS DEFINITION
	// ================================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!object}     options
	 *
	 * @constructor
	 */
	var SelectPayment = function ( element, options ) {
		this.$element = $( element );
		this.options  = _.extend( {}, DEFAULTS, options || {} );

		this.url = makeUrl( { module: 'eshop', manager: 'eshop', action: 'applyPaymentMethodFee' } );

		this.__setEventHandlers();
		this.__init();
	};


	/**
	 * @method request
	 * @fires document#zg-error Payment not selectable
	 */

	/**
	 * @method request
	 * @fires document#zg.selectPayment.paymentReady After click on payment method, call ajax for change it and set payment details in the page
	 */

	SelectPayment.prototype.request = function ( paymentInfo, orderId ) {
		this.$element.addClass( 'loading' );

		$.ajax( {
			type:     'POST',
			url:      this.url,
			data:     { 'paymentMethod': paymentInfo || '', 'orderId': orderId || '' },
			dataType: 'json',

			success: function ( response ) {
				if ( response.fee == 0 ) {
					$( '[data-zg-role="payment-cost-container"]' ).addClass( 'd-none' );
				} else {
					$( '[data-zg-role="payment-cost-container"]' ).removeClass( 'd-none' );
				}

                if( response.fee_converted ){
                    $( '[data-zg-role="payment_cost"]' ).html( window.renderPrice( response.fee_converted ) + ' (' + window.renderPrice( response.fee, null, window.ZG_CONFIG.original_currency ) + ')' );
                }
                else{
                    $( '[data-zg-role="payment_cost"]' ).html( window.renderPrice( response.fee ) );
                }

                if( response.discountAmount_converted ){
                    $( '[data-zg-role="discountAmount"]' ).html( window.renderPrice( response.discountAmount_converted ) + ' (' + window.renderPrice( response.discountAmount, null, window.ZG_CONFIG.original_currency ) + ')' );
                }
                else{
                    $( '[data-zg-role="discountAmount"]').html( window.renderPrice( response.discountAmount ) );
                }

                if( response.total_sum_converted ){
                    $( '[data-zg-role="total_sum"]' ).html( window.renderPrice( response.total_sum_converted ) + ' (' + window.renderPrice( response.total_sum, null, window.ZG_CONFIG.original_currency ) + ')' );
                }
                else{
                    $( '[data-zg-role="total_sum"]' ).html( window.renderPrice( response.total_sum ) );
                }

                if( response.total_sum_converted ){
                    $( '[data-zg-role="total_sum_converted"]' ).html( window.renderPrice( response.total_sum_converted ) );
                    $( '[data-zg-role="total_sum"]' ).html( window.renderPrice( response.total_sum, null, window.ZG_CONFIG.original_currency ) );
                }
                else{
                    $( '[data-zg-role="total_sum"]' ).html( window.renderPrice( response.total_sum ) );
                }

                $( '[data-zg-role="payments"]' ).removeClass( 'd-none' );
				$( document ).trigger( 'zg.selectPayment.paymentReady', [paymentInfo] );
			},

			error: function () {
				$( '[data-zg-role="payments"]' ).addClass( 'd-none' );

				$( document ).trigger(
					'zg-error',
					[{
						eventType: 'SelectPayment',
						message:   JS_TRANSLATIONS.payment_not_selectable || 'Payment not selectable'
					}]
				);
			},

			complete: (function () {
				this.$element.removeClass( 'loading' );
			}).bind( this )
		} );
	};


	/**
	 * Select the first payment method if none is selected
	 *
	 * @private
	 */
	/**
	 * @method __init
	 * @fires document#zg.selectPayment.paymentReady On init of the plugin, check if there is a method checked otherwise click the first
	 */
	SelectPayment.prototype.__init = function () {
		//var $selected = this.$element.find( this.options.selector + ':checked' );
		var paymentSelected = $( '#inputPaymentSelected' ).val();
	    this.request(paymentSelected, this.$element.data('order-id'));
		$( document ).trigger( 'zg.selectPayment.paymentReady', paymentSelected );

		/*
		if ( $selected.length ) {
			$( document ).trigger( 'zg.selectPayment.paymentReady', [$selected.data( 'payment-info' )] );
		} else {

			if(this.$element.find( this.options.selector ).first().prop('disabled'))
				this.$element.find( this.options.selector ).last().prop( 'checked', true ).change();
			else
				this.$element.find( this.options.selector ).first().prop( 'checked', true ).change();
		}
		*/
	};


	/**
	 * @method __setEventHandlers
	 * @listen document#change.zg.selectPayment On radio checked change, call the ajax function
	 */

	SelectPayment.prototype.__setEventHandlers = function () {
		var that = this;
		/*
		this.$element.on( 'change.zg.selectPayment', this.options.selector, (function ( e ) {
			var $target = $( e.currentTarget );

            $target.closest('a').tab('show'); // Show the correct tab

			this.request( $target.data( 'payment-info' ) );
		}).bind( this ) );
		*/
		$( '[data-zg-role="payment-tab"]' ).click( function(){
			var paymentSelected = $( this ).data( 'payment-info' );
      		var orderId = that.$element.data('order-id');
			that.request( paymentSelected, orderId );
			$( '#inputPaymentSelected' ).val( paymentSelected );
		});
	};


	// SELECT PAYMENT PLUGIN DEFINITION
	// =================================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.selectPayment' );
			var options = _.extend( {}, ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.selectPayment', new SelectPayment( this, options ) );
			}
		} );
	}

	$.fn.zgSelectPayment             = Plugin;
	$.fn.zgSelectPayment.Constructor = SelectPayment;


	// SELECT PAYMENT DATA-API
	// ========================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );


}( jQuery, _ ));

// CUSTOM SELECT PAYMENT CURRENCY
(function ( $ ) {
    'use strict';

    function swap(a, b) {
        a = $(a); b = $(b);
        var tmp = $('<span>').hide();
        a.before(tmp);
        b.before(a);
        tmp.replaceWith(b);
    };

    $('input[name="changeSelectedCurrency"]').on( 'change', (function ( e ) {
        e.preventDefault();

        var value       = $(this).val(),
            callback    = {
                success: function () {
                    swap( '[data-zg-role="total_sum_converted"]', '[data-zg-role="total_sum"]' );
                }
            };

        zgPost( 'setCurrencyToPay', { currency: value }, null, callback );
    }) );

})( jQuery );
